import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import {DevseerMaterialColorPickerComponent} from './material-color-picker.component';
import {DevseerPipesModule} from '../../pipes/pipes.module';



@NgModule({
    declarations: [
        DevseerMaterialColorPickerComponent
    ],
    imports: [
        CommonModule,

        FlexLayoutModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,

        DevseerPipesModule
    ],
    exports: [
        DevseerMaterialColorPickerComponent
    ],
})
export class DevseerMaterialColorPickerModule
{
}
