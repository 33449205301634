import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DevseerFileUploaderComponent} from './file-uploader.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [DevseerFileUploaderComponent],
  exports: [
      DevseerFileUploaderComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ]
})
export class FileUploaderModule { }
