import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'keypair'
})
export class KeypairPipe implements PipeTransform {

  transform(value: any, args: any): boolean {
    if (undefined === args) {
      return true;
    }
    if (args.hasOwnProperty(value)) {
      return true;
    } else {
      return false;
    }
  }

}
