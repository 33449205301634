import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {locale as english} from '../i18n/en';
import {locale as macedonian} from '../i18n/mk';
import {takeUntil} from 'rxjs/operators';
import {UsersService} from './users.service';
import {devseerAnimations} from '../../../../../@devseer/animations';
import {DevseerTranslationLoaderService} from '../../../../../@devseer/services/translation-loader.service';
import {UserService} from '../user/user.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DevseerConfirmDialogComponent} from '../../../../../@devseer/components/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-users',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
  animations: devseerAnimations,
  encapsulation: ViewEncapsulation.None
})
export class UsersPage implements OnInit, OnDestroy {

  config: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  columns: any;
  user: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  Router: any;
  model = {table: 'user'};
  serachFilters = ['id', 'name', 'surname', 'phoneWork', 'created_at'];
  searchResults: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  routeData = {
    page: 1,
    sort_by: '',
    limit: 25
  };
  public confirmDialogRef: MatDialogRef<DevseerConfirmDialogComponent>;
  public dialogRef: any;
  private _unsubscribeAll: Subject<any>;

  constructor(private usersServices: UsersService,
              private userService: UserService,
              private route: ActivatedRoute,
              private router: Router,
              private _devseerTranslationLoaderService: DevseerTranslationLoaderService,
              public _matDialog: MatDialog) {

    this._unsubscribeAll = new Subject();

    this.columns = [
      {
        name: 'name',
        label: 'users.name',
        sortable: true,
        class: '',
        searchable: true,
        searchKey: 'name'
      },
      {
        name: 'surname',
        label: 'users.surname',
        sortable: true,
        class: '',
        searchable: true,
        searchKey: 'surname'
      },
      {
        name: 'email',
        label: 'users.email',
        sortable: true,
        class: '',
        searchable: true,
        searchKey: 'email'
      },
      {
        name: 'amount',
        label: 'users.amount',
        sortable: true,
        class: '',
        searchable: true,
        searchKey: 'amount'
      },
      {
        name: 'phone',
        label: 'users.phonePersonal',
        sortable: true,
        class: '',
        searchable: true,
        searchKey: 'phone'
      },
      {
        name: 'Action',
        label: 'users.action',
        sortable: false,
        class: '',
        grouping: false
      }
    ];
    this._devseerTranslationLoaderService.loadTranslations(english, macedonian);

    this.Router = this.route.queryParams;
  }

  ngOnInit(): void {
    this.routeData = {
      page: this.route.snapshot.params.page,
      sort_by: this.route.snapshot.params.sort_by,
      limit: this.route.snapshot.params.limit
    };
    console.log(this.routeData);

    this.usersServices.onUserChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.config.next({
          columns: this.columns,
          data: res,
          route: environment.api + `users/` + `${''}`,
          emptyMessage: '<h5>No users available</h5><p>Please add a new user</p>',
          navbarVisible: true,
          headerVisible: true,
          multiSorting: false,
          search: false,
          selectable: false,
          expandable: false,
          spinner: false,
          grouping: false,
          serialNumber: 'collapse',
          httpHeaders: '',
          routeNew: '/users/new',
        });
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  remove(user): any {
    this.confirmDialogRef = this._matDialog.open(DevseerConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete:  ' + user.profile.full_name;

    this.confirmDialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.userService.removeUser(user).then((ress) => {
            this.usersServices.getUsers(this.routeData);
          });
        }
      });
  }

  limit(event): void {
    this.routeData = {
      page: this.route.snapshot.params.page,
      sort_by: this.route.snapshot.params.sort_by,
      limit: event.limit
    };
    this.switchNavRouter(this.routeData);
  }


  sortByEvent(event): any {
    this.routeData = {
      page: this.route.snapshot.params.page,
      sort_by: event.sort_by,
      limit: this.route.snapshot.params.limit
    };
    this.switchNavRouter(this.routeData);
  }


  pageChangeEvent(event): any {
    this.routeData = {
      page: event.page,
      sort_by: this.route.snapshot.params.sort_by,
      limit: this.route.snapshot.params.limit
    };

    this.switchNavRouter(this.routeData);
  }

  inputSearchChangeEvent(data: any): any {
    const profileData = [];
    for (let i = 0; i < data.value.length; i++) {
      profileData.push(data.value[i]);
    }
    if (data.value.length > 0) {
      this.user.next(profileData);
      this.config.value.data = this.user.value;
      this.searchResults.next(profileData);
    } else {

    }
  }


  switchNavRouter(data: any): any {
    this.router.navigate([`users/${data.page}/${data.sort_by}/${data.limit}`]).then(() => {

    });
  }

  search(response): void {
    if (response.type === 'reload') {
      this.usersServices.getUsers(this.routeData);
      return;
    }
    for (let i = 0; i < response.value.length; i++) {
      response.value[i]['city'] = response.value[i].location.city;
      response.value[i]['street'] = response.value[i].location.street;
      response.value[i]['country'] = response.value[i].location.country;
      if (response.value[i].profile) {
        response.value[i]['firstName'] = response.value[i].profile.name;
        response.value[i]['name'] = response.value[i].profile.name;
        response.value[i]['lastName'] = response.value[i].profile.surname;
        response.value[i]['phone'] = response.value[i].profile.phonePersonal;
        response.value[i]['username'] = response.value[i].profile.full_name;
      }
    }
    this.usersServices.onUserChange.next(response.value);
  }

  // goToWindow(id: number): any {
  //   const url = `http://138.68.97.131/daka/#/project/edit?projects=${id}`;
  //   window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  // }


}
