import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class ErrorHandler {
  constructor(public loadingController: LoadingController,
              private router: Router,
              private matSnackBar: MatSnackBar) {
  }

  async handleError(err: any) {

    if (err === undefined) {
      return;
    }
    console.log('error', err);
    if (err.error.message === 'Not enough or too many segments' ||
      err.error.message === 'Signature has expired' ||
      err.error.message === 'Signature verification raised') {
      localStorage.removeItem('token');
      localStorage.removeItem('profile');
      this.router.navigate(['']);

    }
    this.hideLoader();
    this.matSnackBar.open(err.error.message, 'Cancel', {duration: 2500, horizontalPosition: 'center'});
  }

  hideLoader() {
    this.loadingController.dismiss();
  }
}
