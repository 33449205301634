import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UsersPageRoutingModule} from './users-routing.module';
import {UsersPage} from './users.page';
import {UsersService} from './users.service';
import {UserService} from '../user/user.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxPaginationModule} from 'ngx-pagination';
import {DcDatatableModule} from '../../../../../@devseer/components/dc-datatable/dc-datatable.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatListModule} from '@angular/material/list';
import {TranslateModule} from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRippleModule} from '@angular/material/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import {DcSearchModule} from '../../../../../@devseer/components/dc-search/dc-search.module';
import {DevseerSharedModule} from '../../../../../@devseer/shared.module';
import {MatDialogModule} from '@angular/material/dialog';
import {DevseerConfirmDialogModule, DevseerThemeOptionsModule} from '../../../../../@devseer/components';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {UserPageModule} from '../user/user.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        UsersPageRoutingModule,
        UserPageModule,
        TranslateModule,
        DevseerSharedModule,
        MatIconModule,
        DcDatatableModule,
        MatMenuModule,
        MatListModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSnackBarModule,
        MatTabsModule,
        MatInputModule,
        MatChipsModule,
        MatToolbarModule,
        MatDialogModule,
        DevseerConfirmDialogModule,
        DcSearchModule,
        ScrollingModule,
        NgxPaginationModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        DevseerThemeOptionsModule
    ],
  declarations: [UsersPage],
  providers: [UsersService, UserService]
})
export class UsersPageModule {
}
