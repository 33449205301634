import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef, Input} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {Location} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs/operators';
import {DevseerUtils} from '../../../../../@devseer/utils';
import {devseerAnimations} from '../../../../../@devseer/animations';

import {UserService} from './user.service';
import {DevseerTranslationLoaderService} from '../../../../../@devseer/services/translation-loader.service';
import {locale as english} from '../i18n/en';
import {locale as macedonian} from '../i18n/mk';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../users/users.service';
import {User} from './users.model';
import {Locations} from './location.model';
import {MatDrawer} from '@angular/material/sidenav';
import {Platform} from '@ionic/angular';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DatePipe } from '@angular/common';


interface TabsNavigationItem {
  id: string;
  icon: string;
  title?: string;
  translate?: string;
  description?: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-user',
  templateUrl: './user.page.html',
  
  styleUrls: ['./user.page.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: devseerAnimations
})
export class UserPage implements OnInit, OnDestroy {
  user: User;
  amount = {value: 0};
  generation: Date = new Date(); // Initialize with the current date and time
  birthDate: Date = new Date(); // Initialize with the current date and time

  transactions: any[] = [
    {
      amount: 100,
      created_at: '2021-09-05T01:23:43.296+02:00'
    },
    {
      amount: 600,
      created_at: '2021-10-05T01:23:43.296+02:00'
    },
    {
      amount: 200,
      created_at: '2021-11-05T01:23:43.296+02:00'
    },
    {
      amount: 300,
      created_at: '2021-12-05T01:23:43.296+02:00'
    },
  ];
  location: Locations;
  pageType: string;
  userForm: FormGroup;
  hide = true;
  roles: any[] = [{id: 'admin', name: 'Admin'}, {id: 'staff', name: 'User'}];
  companies: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  routeData: any;
  @ViewChild('drawer') drawer: MatDrawer;
  @Input() panel: TabsNavigationItem;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  selectedPanel: string = 'account';
  plans: any[];
  panelOpenState = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  // Setup available panels
  panels: TabsNavigationItem[] = [
    {
      id: 'account',
      icon: 'account_circle',
      title: 'account',
      translate: 'user.account',
      description: 'Manage your public profile and private information'
    },
    {
      id: 'plan-billing',
      icon: 'credit_card',
      title: 'plan_billing',
      translate: 'users.delete',
      description: 'Manage student payment, all transaction history.'
    },
    {
      id: 'security',
      icon: 'lock',
      title: 'security',
      translate: 'users.delete',
      description: 'Manage student password'
    }
  ];

  /**
   * Constructor
   *
   * @param {ActivatedRoute} _route
   * @param _platform
   * @param {UserService} _userService
   * @param _usersService
   * @param {FormBuilder} _formBuilder
   * @param {Location} _location
   * @param {MatSnackBar} _matSnackBar
   * @param _devseerTranslationLoaderService
   * @param _changeDetectorRef
   */
  constructor(
    private _route: ActivatedRoute,
    private _platform: Platform,
    private _userService: UserService,
    private _usersService: UsersService,
    private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private _location: Location,
    private _matSnackBar: MatSnackBar,
    private _devseerTranslationLoaderService: DevseerTranslationLoaderService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    // Set the default
    this.user = new User();

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this._devseerTranslationLoaderService.loadTranslations(english, macedonian);

    this.routeData = {
      page: this._route.snapshot.params.page,
      sort_by: this._route.snapshot.params.sort_by,
      limit: this._route.snapshot.params.limit
    };
    if (this._route.snapshot.params.id === 'new') {
      this.pageType = 'new';
    } else {
      this.pageType = this._route.snapshot.params.id;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    // Subscribe to update user on changes

    this._userService.onUserChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(user => {
        if (this.pageType === 'new') {
          this.user = new User();
        } else {
          this.user = new User(user);
        }
        this.userForm = this.createUserForm();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create user form
   *
   * @returns {FormGroup}
   */
  createUserForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.user.id],
      username: [this.user.profile.username],
      name: [this.user.profile.name],
      surname: [this.user.profile.surname],
      email: [this.user.email],
      password: [''],
      password_confirmation: [''],
      phonePersonal: [this.user.profile.phonePersonal],
      contractAmount: [this.user.profile.contractAmount],
      amount: [this.user.amount],
      role: 'student',
      full_name: [this.user.profile.full_name],
      street: [this.user.location.street],
      city: [this.user.location.city],
      zip: [this.user.location.zip],
      country: [this.user.location.country],
      company_id: 1,
      is_active_account: [this.user.is_active_account],
      generation: [this.user.generation],
      branch: [this.user.branch],
      unIndex: [this.user.unIndex],
      father_name: [this.user.father_name],
      birthdate: [this.user.birthdate],
      uniemail: [this.user.uniemail],
      citizenship: [this.user.location.citizenship],
      idNumber: [this.user.idNumber],
      yearStudies: [this.user.yearStudies],
      graduated: [this.user.graduated],
      time_type: [this.user.time_type],
      study_type: [this.user.study_type],
      description: [this.user.description],
      notes: [this.user.notes],
    });
  }

  /**
   * Save user
   */
  saveUser(): void {
    const data = this.userForm.getRawValue();
    data.handle = DevseerUtils.handleize(data.name);

    this._userService.saveUser(data)
      .then((response) => {

        // Show the success message
        this._matSnackBar.open('Student saved', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  /**
   * Add user
   */
  addUser(): void {
    const data = this.userForm.getRawValue();
    data.handle = DevseerUtils.handleize(data.name);
    const password = DevseerUtils.generateGUID();
    data.password = password;
    data.password_confirmation = password;
    this.pageType = 'edit';
    this._userService.addUser(data)
      .then((response) => {

        // Show the success message
        this._matSnackBar.open('Student created', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });

        this._location.go(`/user/${this.routeData.page}/${this.routeData.limit}/${this.routeData.sort_by}/` + response.id + '/' + this.user.handle);
      });
  }

  addTransaction(): void {
    if (this.userForm.get('amount').value <= 0) {
      alert('Amount should be larger then 0');
      return;
    }
    this._userService.addTransaction({
      amount: this.userForm.get('amount').value,
      notes: this.userForm.get('notes').value,
      user_id: this.user.id
    }).then((transaction) => {
      this.saveUser();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Navigate to the panel
   *
   * @param panel
   */
  goToPanel(panel: string): void {
    this.selectedPanel = panel;
    if (this._platform.is('mobileweb') || (this._platform.is('mobile'))) {
      this.drawer.close();

    }
    // Close the drawer on 'over' mode
    if (this.drawerMode === 'over') {
      this.drawer.close();
    }
  }

  /**
   * Get the details of the panel
   *
   * @param id
   */
  getPanelInfo(id: string): any {
    return this.panels.find(panel => panel.id === id);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  onGenerationDateChange(event: any) {
    this.generation = event.value;
    this.userForm.get('generation').setValue(this.generation);
  }

  onBirthDateDateChange(event: any) {
    this.birthDate = event.value;
    this.userForm.get('birthdate').setValue(this.birthDate);
  }

  formatGenerationDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy');
  }

  generatePDF(transaction: any) {
    const doc: any = new jsPDF.default();
  
    // Set font size and style
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
  
    // Logo (left corner)
    const logoImage = new Image();
    logoImage.src = 'assets/images/university_logo.png'; // Relative path to your logo
  
    // Wait for the image to load
      const aspectRatio = 250 / 80;
      const maxWidth = 35; // Set your desired max width here
      const maxHeight = maxWidth / aspectRatio;
      doc.addImage(logoImage, 'PNG', 10, 10, maxWidth, maxHeight);
  
      // Title (centered at the top)
      var currentFontSize = doc.getFontSize();

      doc.setFontSize(20);
      doc.setFont("helvetica","bold");
      doc.setTextColor(128, 128, 128); // RGB values for a shade of gray
      doc.text('INVOICE DOCUMENT', doc.internal.pageSize.getWidth() / 2, 20, 'center');
      let bdate;
      if(this.user.birthdate == "") {bdate = '/'} else {bdate = this.formatDate(this.user.birthdate)}
      doc.setFontSize(currentFontSize);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica","normal");

      const studentTableData = [
        ['Name', this.user.profile.name],
        ['Surname', this.user.profile.surname],
        ['ID Number', this.user.idNumber],
        ['UnIndex', this.user.unIndex],
        ['Phone Number', this.user.profile.phonePersonal],
        ['Branch', this.user.branch],
        ['Birthdate', bdate],
        ['City', this.user.location.city],
        ['Generation', this.formatDateYear(this.user.generation)],
        ['Year Studies', this.user.yearStudies],
        ['Father Name', this.user.father_name],
        ['Graduated', this.user.graduated ? 'Yes' : 'No'],
      ];
  
      
      const studentInfo = ['Student Information', ''];
      doc.autoTable({
        head: [studentInfo],
        body: studentTableData,
        startY: 10 + maxHeight + 10, // Adjust startY based on the logo's height
      });
  
      doc.setFont("helvetica","bold");
      doc.text('Transaction History', 10, doc.autoTable.previous.finalY + 10);
      doc.setFont("helvetica","normal");
      const tableHeaders = ['Amount', 'Date'];
      // const tableData = this.user.transactions.map(t => [t.amount, this.formatDate(t.created_at)]);
      const tableData = [[transaction.amount, this.formatDate(transaction.created_at)]];
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: doc.autoTable.previous.finalY + 20,
      });
  
      var pageWidth = doc.internal.pageSize.getWidth();
      var lineHeight = doc.internal.pageSize.getHeight() - 20; // 20 units from the bottom
      var lineStart = pageWidth - 90; // Adjust as needed
      var lineEnd = pageWidth - 20;   // Adjust as needed

      // Draw the line
      doc.line(lineStart, lineHeight, lineEnd, lineHeight);

      // Add text near the line
      var textX = lineStart + 5; // You can adjust this as needed
      var textY = lineHeight - 10; // Positioning text above the line
      doc.setFont("helvetica","bold");
      doc.setFontSize(10); // Adjust the font size as needed
      doc.text('IUS Financial Department', textX, textY);
      doc.save(`${this.user.profile.name}_${this.user.unIndex}.pdf`);
  }
  
  generatePDFAll() {
    const doc: any = new jsPDF.default();
  
    // Set font size and style
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
  
    // Logo (left corner)
    const logoImage = new Image();
    logoImage.src = 'assets/images/university_logo.png'; // Relative path to your logo
  
    // Wait for the image to load
      const aspectRatio = 250 / 80;
      const maxWidth = 35; // Set your desired max width here
      const maxHeight = maxWidth / aspectRatio;
      doc.addImage(logoImage, 'PNG', 10, 10, maxWidth, maxHeight);
  
      // Title (centered at the top)
      var currentFontSize = doc.getFontSize();

      doc.setFontSize(20);
      doc.setFont("helvetica","bold");
      doc.setTextColor(128, 128, 128); // RGB values for a shade of gray
      doc.text('INVOICE DOCUMENT', doc.internal.pageSize.getWidth() / 2, 20, 'center');
      let bdate;
      if(this.user.birthdate == "") {bdate = '/'} else {bdate = this.formatDate(this.user.birthdate)}
      doc.setFontSize(currentFontSize);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica","normal");

      const studentTableData = [
        ['Name', this.user.profile.name],
        ['Surname', this.user.profile.surname],
        ['ID Number', this.user.idNumber],
        ['UnIndex', this.user.unIndex],
        ['Phone Number', this.user.profile.phonePersonal],
        ['Branch', this.user.branch],
        ['Birthdate', bdate],
        ['City', this.user.location.city],
        ['Generation', this.formatDateYear(this.user.generation)],
        ['Year Studies', this.user.yearStudies],
        ['Father Name', this.user.father_name],
        ['Graduated', this.user.graduated ? 'Yes' : 'No'],
      ];
  
      
      const studentInfo = ['Student Information', ''];
      doc.autoTable({
        head: [studentInfo],
        body: studentTableData,
        startY: 10 + maxHeight + 10, // Adjust startY based on the logo's height
      });
  
      doc.setFont("helvetica","bold");
      doc.text('Transaction History', 10, doc.autoTable.previous.finalY + 10);
      doc.setFont("helvetica","normal");
      const tableHeaders = ['Amount', 'Date'];
      const tableData = this.user.transactions.map(t => [t.amount, this.formatDate(t.created_at)]);
      // const tableData = [[transaction.amount, this.formatDate(transaction.created_at)]];
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: doc.autoTable.previous.finalY + 20,
      });
  
      var pageWidth = doc.internal.pageSize.getWidth();
      var lineHeight = doc.internal.pageSize.getHeight() - 20; // 20 units from the bottom
      var lineStart = pageWidth - 90; // Adjust as needed
      var lineEnd = pageWidth - 20;   // Adjust as needed

      // Draw the line
      doc.line(lineStart, lineHeight, lineEnd, lineHeight);

      // Add text near the line
      var textX = lineStart + 5; // You can adjust this as needed
      var textY = lineHeight - 10; // Positioning text above the line
      doc.setFont("helvetica","bold");
      doc.setFontSize(10); // Adjust the font size as needed
      doc.text('IUS Financial Department', textX, textY);
      doc.save(`${this.user.profile.name}_${this.user.unIndex}.pdf`);
  }
  
  
  

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  formatDateYear(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${year}`;
  }
}
