import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {UsersPage} from './users.page';
import {UsersService} from './users.service';
import {AdminGuard} from '../../../../../api/core/admin.guard';


const routes: Routes = [
  {
    path: 'users/:page/:sort_by/:limit',
    component: UsersPage,
    resolve: {
      data: UsersService
    },
    canActivate: [AdminGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersPageRoutingModule {
}
