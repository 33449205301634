import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

import {environment} from '../../../../../environments/environment';
import {UsersService} from '../users/users.service';


@Injectable()
export class UserService implements Resolve<any> {
  providedIn: 'root';
  routeParams: any;
  user: any;
  onUserChanged: BehaviorSubject<any>;
  currentUser: any = JSON.parse(localStorage.getItem('profile'));


  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   * @param {DeviceServices} usersService
   */
  constructor(
    private _httpClient: HttpClient,
    private usersService: UsersService,
  ) {
    // Set the defaults
    this.onUserChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    if (this.routeParams.id === 'new') {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getUser(this.routeParams.id)
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get user
   *
   * @returns {Promise<any>}
   */
  getUser(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.api + environment.users + id)
        .subscribe((response: any) => {
          this.user = response;
          this.onUserChanged.next(this.user);
          resolve(this.user);
        }, reject);
    });
  }


  /**
   * Add user
   *
   * @param user
   * @returns {Promise<any>}
   */
  addUser(user): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.api + environment.users, user)
        .subscribe((response: any) => {
          this.user = response;
          this.onUserChanged.next(this.user);
          resolve(this.user);
        }, reject);


    });
  }

  /**
   * Save user
   *
   * @param user
   * @returns {Promise<any>}
   */
  saveUser(user): Promise<any> {
    return new Promise((resolve, reject) => {
        this._httpClient.put(environment.api + environment.users + `${user.id}`, user)
          .subscribe((response: any) => {
            this.user = response;
            this.onUserChanged.next(response);
            resolve(this.user);
          }, reject);


      }
    );
  }

  removeUser(user): Promise<any> {
    return new Promise((resolve, reject) => {
        this._httpClient.delete(environment.api + environment.users + `${user.id}`)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      }
    );
  }

  addTransaction(form): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.api + environment.student_transactions, form)
        .subscribe((response: any) => {
          this.user = response;
          this.onUserChanged.next(this.user);
          resolve(this.user);
        }, reject);


    });
  }



  

}

