import {DevseerUtils} from '../../../../../@devseer/utils';
import {Locations} from '../../users-management/user/location.model';

export class Company {

  id: number;
  _id: number;
  name: string;
  email: string;
  phone_number: string;
  private_access_key: any;
  private_secret_key: any;
  tax_number: number;
  devices: number[];
  location: Locations;
  logo_url: string;
  website_url: string;
  facebook_url: string;
  tweeter_url: string;
  instagram_url: string;
  created_at: string;
  updated_at: string;
  handle?: string;

  constructor(company?) {
    company = company || {};
    this.id = company.id || '';
    this._id = company._id || DevseerUtils.generateGUID();
    this.name = company.name || '';
    this.email = company.email || '';
    this.phone_number = company.phone_number || '';
    this.private_access_key = this.private_access_key || '';
    this.private_secret_key = this.private_secret_key || '';
    this.tax_number = company.tax_number || '';
    this.location = new Locations(company.location || {});
    this.logo_url = company.logo_url || '';
    this.website_url = company.website_url || '';
    this.facebook_url = company.facebook_url || '';
    this.tweeter_url = company.tweeter_url || '';
    this.instagram_url = company.instagram_url || '';
    this.handle = company.handle || '';
  }
}
