import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchableService {

  constructor(private http: HttpClient) {
  }

  searchA(data, route): Observable<any> {
    const server = environment.api + `${route.table}s/` + `search_${route.table}/` + '?term=' + `${data}`;
    return this.http.get<any>(server);
  }

  destroyColumns(data, route): Observable<any> {
    const server = environment.api + `${route.table}s/` + `${data}`;
    return this.http.delete(server);
  }

}
