import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {FileUploaderConfig, FileUploaderEvent} from '../file-uploader/file-uploader.types';
import {Dimensions, ImageCroppedEvent, ImageTransform} from '../dc-image-upload/interfaces';
import {base64ToFile} from '../dc-image-upload/utils/blob.utils';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class DevseerImageUploaderComponent implements OnInit {

  public config: FileUploaderConfig = {
    max_size: 50000000,
    file_type_name: 'Image',
    accept: '.jpg, .png, .jpeg'
  };

  @Input()
  init_value: any | null = null;

  @Input()
  current_src: SafeUrl;

  @Input()
  current_value: any | null = null;

  current_data: any | null = null;

  @Output()
  image_emitter: EventEmitter<FileUploaderEvent> = new EventEmitter();

  public transforming = false;


  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  constructor() {
  }

  ngOnInit() {
    this.current_value = this.init_value;
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  public reset(): void {
    this.init_value = null;
    this.current_value = null;
    this.current_src = undefined;
    this.transforming = false;

    this.image_emitter.emit(null);
  }

  public changed(event: any): void {
    this.transforming = this.current_value === null && event.form_data !== null;
    if (event === null) {
      this.reset();
      return;
    }
    this.current_value = event.form_data;
    this.current_src = event.file_src;
    this.current_data = event;
    this.image_emitter.emit(event);
  }
}
