import { NgModule } from '@angular/core';
import {DevseerCountdownComponent} from './countdown.component';



@NgModule({
    declarations: [
        DevseerCountdownComponent
    ],
    exports: [
        DevseerCountdownComponent
    ],
})
export class DevseerCountdownModule
{
}
