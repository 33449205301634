import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SecurityContext,
  ViewChild
} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {FileUploaderEvent} from '../file-uploader/file-uploader.types';
import {DevseerUtils} from '../../utils';

@Component({
  selector: 'app-image-transform',
  templateUrl: './image-transform.component.html',
  styleUrls: ['./image-transform.component.scss'],
})
export class DevseerImageTransformComponent implements OnInit, AfterViewInit {

  @Input()
  current_value: any | null = null;

  @Input()
  current_data: any | null = null;

  @Output()
  transform_emitter: EventEmitter<FileUploaderEvent> = new EventEmitter();

  @Input()
  current_src: SafeUrl;

  public moving = false;
  public resizing = false;

  @ViewChild('preview', {static: false}) preview_img: ElementRef;


  public cropper: { x1: number, x2: number, y1: number, y2: number } = {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0
  };

  public start_pos: { x1: number, x2: number, y1: number, y2: number, clientX: number, clientY: number };

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    console.log(this.current_data);

  }

  ngAfterViewInit() {
    setTimeout(() => {
      const w = this.preview_img.nativeElement.offsetWidth;
      const h = this.preview_img.nativeElement.offsetHeight;
      this.cropper = {
        x1: w * 0.10,
        x2: w * 0.90,
        y1: h * 0.10,
        y2: h * 0.90
      };
    }, 0);
  }

  /**
   * Get FormData
   *
   * @return FormData
   */
  gen_form_data(): FormData {
    const form_data: FormData = new FormData();
    form_data.append('_id', DevseerUtils.generateGUID());
    form_data.append('name', this.current_data.file.name);
    form_data.append('size', this.current_data.file.size);
    form_data.append('mime_type', this.current_data.file.type);
    form_data.append('data', this.current_value);
    return form_data;
  }

  public set_move(event: any, resizing = false): void {
    this.resizing = resizing;
    this.moving = !resizing;

    this.start_pos = {
      clientX: this.getClientX(event),
      clientY: this.getClientY(event),
      ...this.cropper
    };
  }

  private clamp_position(maintainSize = false): void {
    const min_width = this.preview_img.nativeElement.offsetWidth * 0.3;
    const min_height = this.preview_img.nativeElement.offsetHeight * 0.3;

    const w = this.cropper.x2 - this.cropper.x1;
    const h = this.cropper.y2 - this.cropper.y1;
    const cw = (this.cropper.x1 + this.cropper.x2) / 2;
    const ch = (this.cropper.y1 + this.cropper.y2) / 2;

    if (w < min_width) {
      this.cropper.x1 = cw - (min_width / 2);
      this.cropper.x2 = cw + (min_width / 2);
    }

    if (h < min_height) {
      this.cropper.y1 = ch - (min_height / 2);
      this.cropper.y2 = ch + (min_height / 2);
    }

    if (this.cropper.x1 < 0) {
      this.cropper.x2 -= maintainSize ? this.cropper.x1 : 0;
      this.cropper.x1 = 0;
    }
    if (this.cropper.y1 < 0) {
      this.cropper.y2 -= maintainSize ? this.cropper.y1 : 0;
      this.cropper.y1 = 0;
    }

    const max_width = this.preview_img.nativeElement.offsetWidth;
    const max_height = this.preview_img.nativeElement.offsetHeight;

    if (this.cropper.x2 > max_width) {
      this.cropper.x1 -= maintainSize ? (this.cropper.x2 - max_width) : 0;
      this.cropper.x2 = max_width;
    }
    if (this.cropper.y2 > max_height) {
      this.cropper.y1 -= maintainSize ? (this.cropper.y2 - max_height) : 0;
      this.cropper.y2 = max_height;
    }

    this.cropper.x1 = Math.max(this.cropper.x1, 0);
    this.cropper.y1 = Math.max(this.cropper.y1, 0);
    this.cropper.x2 = Math.min(this.cropper.x2, max_width);
    this.cropper.y2 = Math.min(this.cropper.y2, max_height);
  }

  private getClientX(event: any): number {
    return (event.touches && event.touches[0] ? event.touches[0].clientX : event.clientX) || 0;
  }

  private getClientY(event: any): number {
    return (event.touches && event.touches[0] ? event.touches[0].clientY : event.clientY) || 0;
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  move(event: any): void {
    if (!this.moving && !this.resizing) {
      return;
    }

    const diffX = this.getClientX(event) - this.start_pos.clientX;
    const diffY = this.getClientY(event) - this.start_pos.clientY;

    if (this.moving) {
      this.cropper.x1 = this.start_pos.x1 + diffX;
      this.cropper.y1 = this.start_pos.y1 + diffY;
      this.cropper.x2 = this.start_pos.x2 + diffX;
      this.cropper.y2 = this.start_pos.y2 + diffY;
    } else if (this.resizing) {

      const width = this.start_pos.x2 - this.start_pos.x1;
      const height = this.start_pos.y2 - this.start_pos.y1;

      const center_x = this.start_pos.x1 + width / 2;
      const center_y = this.start_pos.y1 + height / 2;

      let goal_x = Math.abs(this.getClientX(event) - this.start_pos.clientX);
      let goal_y = Math.abs(this.getClientY(event) - this.start_pos.clientY);

      const rect = this.preview_img.nativeElement.getBoundingClientRect();
      const x = this.getClientX(event) - rect.left;
      const y = this.getClientY(event) - rect.top;

      if (x > this.start_pos.x1 && x < this.start_pos.x2) {
        goal_x = -goal_x;
      }
      if (y > this.start_pos.y1 && y < this.start_pos.y2) {
        goal_y = -goal_y;
      }

      goal_x = (width + goal_x) / 2;
      goal_y = (height + goal_y) / 2;

      const largest = Math.min(goal_x, goal_y);

      this.cropper.x1 = center_x - largest;
      this.cropper.x2 = center_x + largest;
      this.cropper.y1 = center_y - largest;
      this.cropper.y2 = center_y + largest;
    }
    this.clamp_position(true);
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  stop_move(event: any): void {
    this.moving = false;
    this.resizing = false;
  }


  public reset(): void {
    this.transform_emitter.emit(null);
  }

  public accept(): void {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      const ar = Math.min(img.width, img.height);

      const pr = ar / Math.min(this.preview_img.nativeElement.offsetWidth, this.preview_img.nativeElement.offsetHeight);

      ctx.drawImage(img,
        this.cropper.x1 * pr,
        this.cropper.y1 * pr,
        pr * (this.cropper.x2 - this.cropper.x1),
        pr * (this.cropper.y2 - this.cropper.y1),
        0,
        0,
        ar,
        ar);

      this.current_value = canvas.toDataURL();
      this.current_src = this.sanitizer.bypassSecurityTrustUrl(this.current_value);

      this.transform_emitter.emit({
        form_data: this.gen_form_data(),
        file_src: this.current_src,
        type: 'accepted',
        file: this.current_data.file
      });
    };
    img.src = this.sanitizer.sanitize(SecurityContext.URL, this.current_src);
  }


}
