export const locale = {
  lang: 'mk',
  data: {
    users: {
      users: 'Студенти',
      username: 'Корисничко име',
      email: 'Е -пошта',
      name: 'Име',
      surname: 'Презиме',
      phonePersonal: 'Телефонски број',
      amount: 'Цена',
      action: 'Акција',
      edit: 'Уредување',
      delete: 'Избриши',
      add: 'Додај'
    },
    user: {
      add: 'Додај',
      save: 'Зачувај',
      new_user: 'Нов Студент',
      account: 'Сметка',
      security: 'Безбедност',
      plan_billing: 'План и наплата',
      team: 'Тим',
      profile: 'Профил',
      profile_desc: 'Следните информации се јавно прикажани!',
      username: 'Корисничко име',
      name: 'Име',
      surname: 'Презиме',
      personal_info: 'Лични податоци',
      personal_desc: 'Детали за комуникација во случај да сакаме да се поврземе со вас. Овие ќе бидат приватни.!',
      email: 'Е -пошта',
      location: 'Локација',
      location_info: 'Детали за комуникација во случај да сакаме да се поврземе со вас. Овие ќе бидат приватни.!',
      phonePersonal: 'Телефонски број',
      street: 'Улица',
      city: 'Град',
      zip: 'Поштенски',
      country: 'Држава',
      deactivation: 'Деактивирање',
      deactivation_desc: 'Привремено деактивирајте ја сметката.',
      is_active_account: 'Активен',
      change_pass: 'Сменете ја вашата лозинка',
      pass_desc: 'Може да ја смените лозинката само двапати во рок од 24 часа!',
      password: 'Лозинка',
      password_confirm: 'Потврди ја лозинката',
      change_role: 'Променете ги улогите',
      role_desc: 'Следните информации се јавно прикажани, внимавајте!',
      role: 'Улога',
      company: 'Компанија',
      contractAmount: 'Тотална сума на договор',
      branch: 'Факултети',
      generation: 'Датум на регистрација',
      index: 'Индекс',
      father_name: 'Име на татко',
      birthdate: 'Датум на раѓање',
      uniemail: 'Универзитетска е-пошта',
      citizenship: 'Граѓанство',
      idNumber: 'Број на лична карта',
      yearStudies: 'Година на студии',
      graduated: 'Дипломирал',
    },
    'ROLE': {
      'admin': 'Админ',
      'tenant': 'Admin Tenant',
      'device_user': 'User'
    }
  }
};
