import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {DevseerSharedModule} from '../../../../@devseer/shared.module';
import {DevseerSearchBarModule, DevseerShortcutsModule} from '../../../../@devseer/components';
import {ToolbarComponent} from './toolbar.component';


@NgModule({
  declarations: [
    ToolbarComponent
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,

    DevseerSharedModule,
    DevseerSearchBarModule,
    DevseerShortcutsModule
  ],
  exports: [
    ToolbarComponent
  ]
})
export class ToolbarModule {
}
