import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';

import {LoaderState} from './loader';

@Injectable()

export class LoaderService {

    private loaderSubject = new Subject<LoaderState>();

    loaderState = this.loaderSubject.asObservable();

    constructor() {
    }

    show(): void {
        this.loaderSubject.next({show: true});
    }

    hide(): void {
        this.loaderSubject.next({show: false});
    }
}
