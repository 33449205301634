import {DevseerUtils} from '../../../../../@devseer/utils';
import {Profile} from './profile.model';
import {Locations} from './location.model';
import {Company} from './company.model';


export class User {
  id: number;
  email: string;
  is_active_account: boolean;
  company: Company;
  created_by?: number;
  belongs_to: number;
  location: Locations;
  profile: Profile;
  role: string;
  role_ids?: any;
  session?: any;
  company_permit: any | undefined;
  handle?: string;
  created_at?: string;
  updated_at?: string;
  transactions?: any;
  amount?: number;
  payed?: number;
  pending?: number;
  generation: any;
  branch: any;
  unIndex: any;
  father_name: any;
  birthdate: any;
  uniemail: any;
  citizenship: any;
  time_type: any;
  study_type: any;
  idNumber:any;
  yearStudies:any;
  graduated: boolean | false;
  description: any;
  notes: any;

  /**
   * Constructor
   *
   * @param user
   */
  constructor(user?) {
    user = user || {};
    this.created_at = user.created_at || '';
    this.email = user.email || this.generateRandomEmail();
    this.payed = user.payed || 0;
    this.pending = user.pending || 0;
    this.is_active_account = user.is_active_account || false;
    this.role = user.role || '';
    this.role_ids = user.role_ids || '';
    this.session = user.session || '';
    this.updated_at = user.updated_at || '';
    this.company_permit = user.id || '';
    this.amount = user.amount || '';
    this.generation = user.generation || '';
    this.father_name = user.father_name || '';
    this.birthdate = user.birthdate || '';
    this.uniemail = user.uniemail || '';
    this.citizenship = user.citizenship || '';
    this.idNumber = user.idNumber || '';
    this.yearStudies = user.yearStudies || '';
    this.time_type = user.time_type || '';
    this.study_type = user.study_type || '';
    this.graduated = user.graduated || false;
    this.notes = user.notes || '';
    this.branch = user.branch || 'FACULTY OF POLITICAL SCIENCES';
    this.unIndex = user.unIndex || '';
    this.id = user.id || DevseerUtils.generateGUID();
    this.created_by = user.created_by;
    this.description = user.description || '';
    this.belongs_to = user.belongs_to;
    this.company = new Company(user.company || {});
    this.profile = new Profile(user.profile || {});
    this.location = new Locations(user.location || {});
    this.handle = user.handle || DevseerUtils.handleize(this.profile.name);
    this.transactions = user.transactions || [];

  }

  generateRandomEmail(): string {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const emailLength = 7;
    let randomEmail = '';
    for (let i = 0; i < emailLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomEmail += characters[randomIndex];
    }
    return `student_${randomEmail}@example.com`;
  }
}
