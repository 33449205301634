import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import { locale as navigationEnglish } from './navigation/i18n/en';
import { locale as navigationMacedonian } from './navigation/i18n/mk';
import {navigation as navigation} from './navigation/navigation';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {DevseerTranslationLoaderService} from '../@devseer/services/translation-loader.service';
import {DevseerSplashScreenService} from '../@devseer/services/splash-screen.service';
import {DevseerSidebarService} from '../@devseer/components/sidebar/sidebar.service';
import {DevseerConfigService} from '../@devseer/services/config.service';
import {DevseerNavigationService} from '../@devseer/components/navigation/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy
{
  devseerConfig: any;
  navigation: any = navigation;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {DevseerConfigService} _devseerConfigService
   * @param {DevseerNavigationService} _devseerNavigationService
   * @param {DevseerSidebarService} _devseerSidebarService
   * @param {DevseerSplashScreenService} _devseerSplashScreenService
   * @param {DevseerTranslationLoaderService} _devseerTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _devseerConfigService: DevseerConfigService,
    private _devseerNavigationService: DevseerNavigationService,
    private _devseerSidebarService: DevseerSidebarService,
    private _devseerSplashScreenService: DevseerSplashScreenService,
    private _devseerTranslationLoaderService: DevseerTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform
  )
  {
    // Get default navigation
    this.navigation = this.navigation;

    // Register the navigation to the service
    this._devseerNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._devseerNavigationService.setCurrentNavigation('main');

    // Add languages
    this._translateService.addLangs(['en', 'mk']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._devseerTranslationLoaderService.loadTranslations(navigationEnglish, navigationMacedonian);

    // Use a language
    this._translateService.use('en');


    // Add is-mobile class to the body if the platform is mobile
    if ( this._platform.ANDROID || this._platform.IOS )
    {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    // Subscribe to config changes
    this._devseerConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {

        this.devseerConfig = config;

        console.log('devseerConfig.layout.style', this.devseerConfig.layout.style);
        // Boxed
        if ( this.devseerConfig.layout.width === 'boxed' )
        {
          this.document.body.classList.add('boxed');
        }
        else
        {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for ( let i = 0; i < this.document.body.classList.length; i++ )
        {
          const className = this.document.body.classList[i];

          if ( className.startsWith('theme-') )
          {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.devseerConfig.colorTheme);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void
  {
    this._devseerSidebarService.getSidebar(key).toggleOpen();
  }
}
