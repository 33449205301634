export const locale = {
  lang: 'en',
  data: {
    nav: {
      pages: 'Pages',
      dashboard: 'Dashboard',
      students_management: 'Student Management',
      user_management: 'Student Management',
      companies_management: 'Companies Management',
      products_management: 'Products Management',
      categories_management: 'Categories Management',
      orders: 'Orders'
    }
  }
};
