import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {DcSearchComponent} from './dc-search.component';
import {FlexModule} from '@angular/flex-layout';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SearchableService} from './search/searchable.service';


@NgModule({
    declarations: [
        DcSearchComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        FlexModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatInputModule,
        TranslateModule,
        MatProgressSpinnerModule
    ],
    exports     : [
        DcSearchComponent
    ],
    providers: [SearchableService]
})
export class DcSearchModule
{
}
