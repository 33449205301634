import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {DevseerSharedModule} from '../../../../../../@devseer/shared.module';
import {NavbarHorizontalStyle1Component} from './style-1.component';
import {DevseerNavigationModule} from '../../../../../../@devseer/components';



@NgModule({
    declarations: [
        NavbarHorizontalStyle1Component
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,

        DevseerSharedModule,
        DevseerNavigationModule
    ],
    exports     : [
        NavbarHorizontalStyle1Component
    ]
})
export class NavbarHorizontalStyle1Module
{
}
