import {NgModule} from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {DevseerSharedModule} from '../../../../@devseer/shared.module';
import {NavbarModule} from '../../components/navbar/navbar.module';
import {ToolbarModule} from '../../components/toolbar/toolbar.module';
import {DevseerSidebarModule, DevseerThemeOptionsModule} from '../../../../@devseer/components';
import {FooterModule} from '../../components/footer/footer.module';
import {ContentModule} from '../../components/content/content.module';
import {HorizontalLayout1Component} from './layout-1.component';
import {QuickPanelModule} from '../../components/quick-panel/quick-panel.module';


@NgModule({
  declarations: [
    HorizontalLayout1Component
  ],
  imports: [
    MatSidenavModule,

    DevseerSharedModule,
    DevseerSidebarModule,
    DevseerThemeOptionsModule,

    ContentModule,
    FooterModule,
    NavbarModule,
    QuickPanelModule,
    ToolbarModule
  ],
  exports: [
    HorizontalLayout1Component
  ]
})
export class HorizontalLayout1Module {
}
