import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserPage } from './user.page';
import {UserService} from './user.service';


const routes: Routes = [
  {
    path: 'user/:page/:sort_by/:limit/:id',
    component: UserPage,
    resolve: {
      data: UserService
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserPageRoutingModule {}
