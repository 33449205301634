import {DevseerUtils} from '../../../../../@devseer/utils';

export class Profile {
  description: string;
  id: number;
  name: string;
  full_name: string;
  username: string;
  phoneWork: number;
  phonePersonal: number;
  avatarUrl: string;
  surname: string;
  user_id: number;
  session_background_color: string;
  session_color: string;
  activity_background_color: string;
  activity_color: string;
  profile_background_color: string;
  profile_color: string;
  created_at: string;
  updated_at: string;
  contractAmount?: number;
  status?: boolean;
  birthdate?: any;
  yearsOfStudy?: any;
  yearOfContract?: any;

  constructor(profile?) {
    profile = profile || {};

    this.id = profile.id || DevseerUtils.generateGUID();
    this.name = profile.name || '';
    this.description = profile.declarations || '';
    this.full_name = profile.full_name || '';
    this.username = profile.username || '';
    this.phoneWork = profile.phoneWork || '';
    this.phonePersonal = profile.phonePersonal || '';
    this.avatarUrl = profile.avatarUrl || '';
    this.surname = profile.surname || '';
    this.user_id = profile.user_id || '';
    this.session_background_color = profile.session_background_color || '';
    this.session_color = profile.session_color || '';
    this.activity_background_color = profile.activity_background_color || '';
    this.activity_color = profile.activity_color || '';
    this.profile_background_color = profile.profile_background_color || '';
    this.profile_color = profile.profile_color || '';
    this.contractAmount = profile.contractAmount || 0;
    this.status = profile.status || false;
    this.birthdate = profile.birthdate || '';
    this.yearsOfStudy = profile.yearsOfStudy || '';
    this.yearOfContract = profile.yearOfContract || '';

  }
}
