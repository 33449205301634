import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {DevseerSharedModule} from '../../../../@devseer/shared.module';
import {NavbarModule} from '../../components/navbar/navbar.module';
import {ToolbarModule} from '../../components/toolbar/toolbar.module';
import {FooterModule} from '../../components/footer/footer.module';
import {ContentModule} from '../../components/content/content.module';
import {DevseerSidebarModule} from '../../../../@devseer/components';
import {VerticalLayout3Component} from './layout-3.component';
import {QuickPanelModule} from '../../components/quick-panel/quick-panel.module';



@NgModule({
    declarations: [
        VerticalLayout3Component
    ],
    imports     : [
        RouterModule,

        DevseerSharedModule,
        DevseerSidebarModule,

        ContentModule,
        FooterModule,
        NavbarModule,
        QuickPanelModule,
        ToolbarModule
    ],
    exports     : [
        VerticalLayout3Component
    ]
})
export class VerticalLayout3Module
{
}
