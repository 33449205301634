export const locale = {
  lang: 'mk',
  data: {
    'SEARCH': {
      'SEARCH': 'Пребарување',
    },
    'TABLE': {
      'user': 'Студентс',
      'categorie': 'Категории',
      'item': 'Предмети',
      'type': 'Тип',
      'product': 'Производи',
      'prodtype': 'Тип на производ',
      'order': 'Нарачки',
      'companie': 'Компании'
    }
  },
};
