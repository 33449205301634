import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {DevseerSharedModule} from '../../../../../../@devseer/shared.module';
import {NavbarVerticalStyle2Component} from './style-2.component';
import {DevseerNavigationModule} from '../../../../../../@devseer/components';



@NgModule({
    declarations: [
        NavbarVerticalStyle2Component
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,

        DevseerSharedModule,
        DevseerNavigationModule
    ],
    exports     : [
        NavbarVerticalStyle2Component
    ]
})
export class NavbarVerticalStyle2Module
{
}
