import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {DevseerSharedModule} from '../../../../@devseer/shared.module';
import {QuickPanelComponent} from './quick-panel.component';
import {IonicModule} from '@ionic/angular';


@NgModule({
    declarations: [
        QuickPanelComponent
    ],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,

    DevseerSharedModule,
    IonicModule,
  ],
    exports: [
        QuickPanelComponent
    ]
})
export class QuickPanelModule
{
}
