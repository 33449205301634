import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {UserPageRoutingModule} from './user-routing.module';


import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {DevseerDirectivesModule} from '../../../../../@devseer/directives/directives';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatInputModule} from '@angular/material/input';
import {DevseerWidgetModule} from '../../../../../@devseer/components';
import {UserPage} from './user.page';
import {TranslateModule} from '@ngx-translate/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRadioModule} from '@angular/material/radio';
import {MatListModule} from "@angular/material/list";
import {MatExpansionModule} from "@angular/material/expansion";
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UserPageRoutingModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    DevseerDirectivesModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    DevseerWidgetModule,
    TranslateModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatRadioModule,
    MatListModule,
    MatExpansionModule,
    MatDatepickerModule,
  ],
  declarations: [UserPage],
  providers: [
    DatePipe, // Add DatePipe to the providers array
  ],
})
export class UserPageModule {
}
