import { UsersPageModule } from './main/pages/users-management/users/users.module';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';

import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';

import {
  DevseerProgressBarModule,
  DevseerSidebarModule,
  DevseerThemeOptionsModule,
} from '../@devseer/components';

import {DevseerSharedModule} from '../@devseer/shared.module';
import {DevseerModule} from '../@devseer/devseer.module';
import {devseerConfig} from './devseer-config';

import {LayoutModule} from './layout/layout.module';
import {PagesModule} from './main/pages/pages.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {LoaderService} from '../api/core/loader/loader.service';
import {AdminGuard} from '../api/core/admin.guard';
import {AdminGuardService} from '../api/core/admin.guard.service';
import {ErrorHandler} from '../api/core/error_handler';
import {LoaderComponent} from '../api/core/loader/loader.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {RequestInterceptor} from '../api/core/http_interceptor';
import {LoginService} from '../api/authentication/login.service';
import {ProfileService} from '../api/profile/profile.service';


@NgModule({
  declarations: [AppComponent, LoaderComponent],
  entryComponents: [],
  imports: [
    IonicModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Devseer modules
    DevseerModule.forRoot(devseerConfig),
    DevseerProgressBarModule,
    DevseerSharedModule,
    DevseerSidebarModule,
    DevseerThemeOptionsModule,

    // App modules
    LayoutModule,
    PagesModule,
    MatProgressBarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    [
      {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      }
    ],
    AdminGuard,
    AdminGuardService,
    ErrorHandler,
    LoaderService,
    LoginService,
    ProfileService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
