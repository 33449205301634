import {DevseerUtils} from '../../../../../@devseer/utils';

// Location class name is reserved by @Angular.
export class Locations {
  id: number;
  city: string;
  country: string;
  country_code: string;
  lat: string;
  long: string;
  citizenship: string;
  street: string;
  zip: string;
  created_at: string;
  updated_at: string;

  constructor(location?) {
    location = location || {};
    this.id = location.id || DevseerUtils.generateGUID();
    this.city = location.city || '';
    this.country = location.country || '';
    this.country_code = location.country_code || '';
    this.created_at = location.created_at || '';
    this.citizenship = location.citizenship || '';
    this.lat = location.lat || '';
    this.long = location.long || '';
    this.street = location.street || '';
    this.zip = location.zip || '';
  }
}

