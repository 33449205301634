import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {Router} from '@angular/router';
import {locale as english} from './i18n/en';
import {locale as macedonian} from './i18n/mk';
import {DevseerTranslationLoaderService} from '../../services/translation-loader.service';
import {SearchableService} from './search/searchable.service';

@Component({
  selector: 'app-dc-search',
  templateUrl: './dc-search.component.html',
  styleUrls: ['./dc-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DcSearchComponent implements OnInit {

  loading: boolean = false;
  @Output() onButtonClickView = new EventEmitter<object>();
  @Output() onSearchEnded = new EventEmitter<object>();


  @Input() autocomplete: boolean = true;

  @Input()
  get tableVal(): any {
    return this.table.value;
  }

  set tableVal(val: any) {
    if (this.table.value !== val) {
      this.table.next(val);
      this.routeLink = `/${this.table.value.table}/edit`;
      this.queryParams[this.table.value.table] = 1;
    }
  }

  @Input()
  get colFilterVal(): any {
    return this.columnsFilters.value;
  }

  set colFilterVal(val: any) {
    if (this.columnsFilters.value !== val) {
      this.columnsFilters.next(val);
    }
  }

  @Input()
  get searchFilterResult(): any {
    return this.searches.value;
  }

  set searchFilterResult(val: any) {
    if (this.searches.value !== val) {
      this.searches.next(val);
    }
  }

  @Input()
  get displayKeys(): any {
    return this.columnsDisplay.value;
  }

  set displayKeys(val: any) {
    if (this.columnsDisplay.value !== val) {
      this.columnsDisplay.next(val);
    }
  }


  table: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  searchCtr = new FormControl();
  private searchDecouncer$: Subject<string> = new Subject();
  filtersearch: any;
  searchFilter: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  searches: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  columnsFilters: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(undefined);
  columnsDisplay: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(undefined);
  selected: any;
  public inputValue = '';
  public debouncedInputValue = this.inputValue;
  private routeLink;
  private queryParams = {};

  /**
   * Constructor
   *
   * @param {SearchableService} serachableService
   * @param {Router} router
   * @param {DevseerTranslationLoaderService} _devseerTranslationLoaderService
   */
  constructor(private serachableService: SearchableService,
              private router: Router,
              private _devseerTranslationLoaderService: DevseerTranslationLoaderService) {
    this._devseerTranslationLoaderService.loadTranslations(english, macedonian);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------


  ngOnInit(): void {
    this.setupSearchDebouncer();
  }


  /**
   *  Search Debouncer
   *  default: 600ms
   *   if humanize default => 350;
   */
  private setupSearchDebouncer(): void {
    this.searchDecouncer$.pipe(
      debounceTime(600),
      distinctUntilChanged(),
    ).subscribe((term: string) => {
      this.debouncedInputValue = term;
      if (term === undefined) {
        return;
      }
      if (term.length === 0) {
        this.searches.next(undefined);
        this.onSearchEnded.emit({type: 'reload'});
      }
      if (term !== '') {
        this.search(term);
      }
    });
  }


  /**
   *  onSearchInputChanges
   *  @param term: String
   */

  public onSearchInputChanges(term: string): void {
    this.searchDecouncer$.next(term);
  }

  /**
   * FilterPropertyByKeys
   *  _filteredApplicants()
   *  @param value: String
   *  this doesn't work correctly currenty only predefined keys;
   *
   */
  _filteredApplicants(value: string): any {
    const filterValue = value.toLowerCase();
    return this.searchFilter.value.filter(data =>
      (String(data.id).toLowerCase().indexOf(filterValue) >= 0) ||
      (String(data.name).toLowerCase().indexOf(filterValue) >= 0) ||
      (String(data.phoneWork).toLowerCase().indexOf(filterValue) >= 0) ||
      (String(data.date_birth).toLowerCase().indexOf(filterValue) >= 0) ||
      (String(data.email).toLowerCase().indexOf(filterValue) >= 0));
  }

  toFormattedDate(iso): any {
    const date = new Date(iso);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  parse(value: any): Date | null {
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  private search(term: string): void {
    if (term.length > 3) {
      this.loading = true;
      this.serachableService.searchA(term, this.table.value).pipe(map((response: any) => {
        this.searchFilter.next(response);
        this.filtersearch = this.searchCtr.valueChanges
          .pipe(
            startWith(''),
            map(data => data ? this._filteredApplicants(data) : response.slice())
          );
        this.searches.next(response);

        this.onSearchEnded.emit(this.searches);
        this.loading = false;
        console.log(this.columnsDisplay.value);
      }, error => this.loading = false)).subscribe(() => {
      }, error => this.loading = false);
    }

  }


  onSelectRoute(data): any {
    this.onButtonClickView.emit(data);
    this.selected = data;
  }
}
