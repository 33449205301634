import {NgModule} from '@angular/core';
import {DevseerInnerScrollDirective} from './devseer-inner-scroll/devseer-inner-scroll.directive';
import {DevseerIfOnDomDirective} from './devseer-if-on-dom/devseer-if-on-dom.directive';
import {DevseerPerfectScrollbarDirective} from './devseer-perfect-scrollbar/devseer-perfect-scrollbar.directive';
import {DevseerMatSidenavHelperDirective, DevseerMatSidenavTogglerDirective} from './devseer-mat-sidenav/devseer-mat-sidenav.directive';
import {DevseerRoleDirective} from './role/devseer-role.directive';




@NgModule({
  declarations: [
    DevseerIfOnDomDirective,
    DevseerInnerScrollDirective,
    DevseerMatSidenavHelperDirective,
    DevseerMatSidenavTogglerDirective,
    DevseerPerfectScrollbarDirective,
    DevseerRoleDirective
  ],
  imports: [],
  exports: [
    DevseerIfOnDomDirective,
    DevseerInnerScrollDirective,
    DevseerMatSidenavHelperDirective,
    DevseerMatSidenavTogglerDirective,
    DevseerPerfectScrollbarDirective,
    DevseerRoleDirective
  ]
})
export class DevseerDirectivesModule {
}
