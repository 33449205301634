import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, finalize, map} from 'rxjs/operators';
import {LoginService} from '../authentication/login.service';
import {ErrorHandler} from './error_handler';
import {LoaderService} from './loader/loader.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private loader: LoaderService, private loginService: LoginService, public errorService: ErrorHandler) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token
        }
      });
    }


    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        return throwError(error);
      }));
  }

}
