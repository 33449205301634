import {
    AfterContentChecked,
    Directive,
    OnDestroy,
    OnInit, Output,
    EventEmitter, Input
} from '@angular/core';

@Directive({selector: '[dcRoleCheck]'})
export class DevseerRoleDirective implements OnInit, OnDestroy, AfterContentChecked {

    @Output() onRoleCheck = new EventEmitter();
    @Input() editingEnabled: any;
    @Input() key: any;
    @Input() type: any;
    role: any = JSON.parse(localStorage.getItem('profile'));
    permissions: any = JSON.parse(localStorage.getItem('permissions'));


    constructor() {
        // Set the defaults
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this.permissions.permissions[this.key] === undefined) {
            return;
        }
        console.log('type', this.type)
        console.log('this.permissions.permissions[this.key] ', this.permissions.permissions[this.key].attributes[this.type] );
        if (this.permissions.permissions[this.key].attributes[this.type]) {
            console.log(this.permissions.permissions[this.key].attributes);
        } else {
            this.editingEnabled._elementRef.nativeElement.remove();
        }
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    /**
     * After content checked
     */
    ngAfterContentChecked(): void {

    }
}
