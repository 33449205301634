export const locale = {
    lang: 'en',
    data: {
        'DCDATATABLE': {
            'SHOW': 'Show',
            'TOTAL': 'Total',
            'ADDNEWGROUP': 'Add new group',
            'NAME': 'Name',
            'SCREEN': 'Screen',
            'ACTION': 'Action',
            'EDIT': 'Edit',
            'REMOVE': 'Remove',
        }
    }
};
