export const locale = {
  lang: 'en',
  data: {
    'SEARCH': {
      'SEARCH': 'Search',
    },
    'TABLE': {
      'user': 'Students',
      'categorie': 'Categories',
      'item': 'Items',
      'type': 'Type',
      'product': 'Products',
      'prodtype': 'Product types',
      'order': 'Orders',
      'companie': 'Companies'

    }
  },
};
