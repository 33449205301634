export const locale = {
    lang: 'mk',
    data: {
        'DCDATATABLE': {
            'SHOW': 'Покажи',
            'TOTAL': 'Вкупно',
            'ADDNEWGROUP': 'Add new group',
            'NAME': 'Name',
            'SCREEN': 'Screen',
            'ACTION': 'Action',
            'EDIT': 'Edit',
            'REMOVE': 'Remove',
        }
    }
};
