import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {DEVSEER_CONFIG} from './services/config.service';



@NgModule()
export class DevseerModule
{
  constructor(@Optional() @SkipSelf() parentModule: DevseerModule)
  {
    if ( parentModule )
    {
      throw new Error('DevseerModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(config): ModuleWithProviders<DevseerModule>
  {
    return {
      ngModule : DevseerModule,
      providers: [
        {
          provide : DEVSEER_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
