import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DevseerTranslationLoaderService} from 'src/@devseer/services/translation-loader.service';
import {FileUploaderConfig, FileUploaderEvent} from './file-uploader.types';
import {locale as en} from './i18n/en';
import {locale as mk} from './i18n/en';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class DevseerFileUploaderComponent implements OnInit {
  @Input()
  public config: FileUploaderConfig;
  is_uploading = false;

  @Input()
  current_value: any | null = null;

  @Output()
  file_emitter: EventEmitter<FileUploaderEvent> = new EventEmitter();

  current_src: SafeUrl;

  error_msg = '';

  /**
   * Constructor
   *
   * @param {HttpClient} http
   * @param {DomSanitizer} sanitizer
   * @param {DevseerTranslationLoaderService} devSeerTranslate
   */
  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private devSeerTranslate: DevseerTranslationLoaderService) {
    this.devSeerTranslate.loadTranslations(en, mk);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    if (this.current_value) {
      this.current_src = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(this.current_value));
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * File select
   * @param event: any
   */
  file_select(event: any): void {
    this.error_msg = '';
    this.is_uploading = false;
    const file = (event.target.files || event.srcElement.files)[0];

    if (!file) {
      return;
    }

    const file_ext_reg: RegExp = /(?:\.([^.]+))?$/;
    const valid_format = this.config.accept.includes(file_ext_reg
      .exec(file.name)[1]
      .toLowerCase());
    const valid_size = file.size <= this.config.max_size;

    if (valid_size && valid_format) {
      this.upload(file);
    } else {
      this.error_msg = !valid_format ? 'format' : 'size';
    }
  }

  /**
   * Get FormData
   *
   * @return FormData
   */
  gen_form_data(): FormData {
    const form_data: FormData = new FormData();
    form_data.append('name', this.current_value?.name || '');
    form_data.append('size', this.current_value?.size || 0);
    form_data.append('mime_type', this.current_value?.type || '');
    form_data.append('data', this.current_value || '');
    return form_data;
  }

  /**
   * Reset
   */
  reset(): void {
    this.current_value = null;
    this.current_src = undefined;
    this.is_uploading = false;
    this.file_emitter.emit(null);
  }

  /**
   * Upload
   * @param {File} file
   */
  upload(file: any): void {
    this.is_uploading = true;
    setTimeout(() => {
      this.is_uploading = false;
      this.current_src = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      this.current_value = file;
      this.file_emitter.emit({form_data: this.gen_form_data(), file_src: this.current_src, file: this.current_value});
    }, 500);
  }
}
