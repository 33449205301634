export const locale = {
    lang: 'en',
    data: {
      'FILEUPLOADER': { 
        'format': 'invalid file format',
        'size': 'invalid file size',
        'aspect_ratio': 'width and height need to be equal'
      }
    }
};
