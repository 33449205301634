import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DevseerImageUploaderComponent} from './image-uploader.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {FileUploaderModule} from '../file-uploader/file-uploader.module';
import {ImageTransformModule} from '../image-transform/image-transform.module';
import {ImageCropperModule} from '../dc-image-upload/image-cropper.module';

@NgModule({
  declarations: [DevseerImageUploaderComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FileUploaderModule,
    ImageTransformModule,
    ImageCropperModule
  ],
  exports: [
    DevseerImageUploaderComponent
  ]
})
export class ImageUploaderModule { }
