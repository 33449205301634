import { NgModule } from '@angular/core';
import {DevseerHighlightComponent} from './highlight.component';



@NgModule({
    declarations: [
        DevseerHighlightComponent
    ],
    exports: [
        DevseerHighlightComponent
    ],
})
export class DevseerHighlightModule
{
}
