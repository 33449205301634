import {
  Component,
  OnInit,
  Input,
  ContentChild,
  TemplateRef,
  Inject,
  Output,
  EventEmitter, ViewEncapsulation,
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import * as _ from 'lodash';
import {ActivatedRoute,  Router} from '@angular/router';
import {PaginationInstance} from 'ngx-pagination';
import {DOCUMENT} from '@angular/common';
import {locale as english} from './i18n/en';
import {locale as macedonian} from './i18n/mk';
import {devseerAnimations} from '../../animations';
import {DevseerTranslationLoaderService} from '../../services/translation-loader.service';


@Component({
  selector: 'app-dc-datatable',
  templateUrl: './dc-datatable.component.html',
  styleUrls: ['./dc-datatable.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: devseerAnimations
})
export class DcDatatableComponent implements OnInit {

  @Input()
  // tslint:disable-next-line:typedef
  get configVal() {
    return this.config.value;
  }

  set configVal(val: any) {
    if (this.config.value !== val) {
      this.config.next(val);
      this.initDataTable();
    }
  }

  @Input()
  get tableVal() {
    return this.table.value;
  }

  set tableVal(val: any) {
    if (this.table.value !== val) {
      this.table.next(val);
    }
  }

  @Input()
  get colFilterVal() {
    return this.columnsFilters.value;
  }

  set colFilterVal(val: any) {
    if (this.columnsFilters.value !== val) {
      this.columnsFilters.next(val);
    }
  }

  @Input()
  get searchResultModified() {
    return this.searchResult.value;
  }

  set searchResultModified(val: any) {
    if (this.searchResult.value !== val) {
      this.searchResult.next(val);
      this.rows.next(this.searchResult.value);
    }
    if (this.searchResult.value === null) {

    }
  }


  @Output() refreshBtn = new EventEmitter<object>();
  @Output() onButtonClickSort = new EventEmitter<object>();
  @Output() onButtonClickPage = new EventEmitter<object>();
  @Output() onSearchKey = new EventEmitter<object>();
  @Output() remover = new EventEmitter<object>();
  @Output() exelClick = new EventEmitter<object>();
  @Output() pdfClick = new EventEmitter<object>();
  @Output() onRowClick = new EventEmitter<number>();

  public config: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public table: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public columnsFilters: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public searchResult: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public columns: any = [];
  public route: any;
  public colSpans: any[];
  public profileParams: any;
  @ContentChild('tablerow', {static: false}) public tablerow: TemplateRef<any>;
  @ContentChild('rowGroups', {static: false}) public rowGroups: TemplateRef<any>;
  @ContentChild('rowDetails', {static: false}) public rowDetails: TemplateRef<any>;
  @ContentChild('selectedBtnsGroups', {static: false}) public selectedBtnsGroups: TemplateRef<any>;
  @ContentChild('btnGroups', {static: false}) public btnGroups: TemplateRef<any>;

  public filter = '';
  public maxSize = 7;
  public directionLinks = true;
  public autoHide = false;
  public configPaginate: PaginationInstance = {
    id: 'advanced',
    itemsPerPage: 25,
    currentPage: 1,
    totalItems: 1
  };

  public labels: any = {
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'Page',
    screenReaderCurrentLabel: `You're on page`
  };

  public watchSuccess: Subject<any> = new Subject();
  public params: any = {};
  public rowsIndex = 0;
  public rows: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public page: any = 1;
  public limit: any = 25;
  public dataLoaded = false;
  public expandAll = false;
  public selectAll = false;
  public selectedCount = 0;
  public addSearch = false;
  public searchItems: any = [];
  public searchBy: any;
  public searchName: any;
  public sort: any;
  public sorting: any = {};
  public enableSearch = false;
  public searchValue = '';
  public checkBoxPrefix = Math.random().toString(36).slice(2);
  public spinner = true;
  private http: HttpClient;
  public searchCtrl: FormControl;
  public sortByColumn: any = [];
  public sortCols: any = [];
  public datableRouter: any;
  public windowInnerHeight = 200;

  constructor(@Inject(HttpClient) http: HttpClient,
              private routeUrl: ActivatedRoute,
              private _route: Router,
              private _devseerTranslationLoaderService: DevseerTranslationLoaderService,
              @Inject(DOCUMENT) public document: Document) {
    setTimeout(() => {
      this.profileParams = JSON.parse(localStorage.getItem('profile'));
    }, 1000);
    this.windowInnerHeight = (75 / 100) * window.innerHeight;
    this.http = http;
    this.searchCtrl = new FormControl();
    this.routeUrl.params.subscribe(parameter => {
      this.datableRouter = parameter;
    });
    this.params.page = this.datableRouter.page;
    this.params.limit = this.datableRouter.limit;
    this.configPaginate.itemsPerPage = Number(this.params.limit);
    this._devseerTranslationLoaderService.loadTranslations(english, macedonian);
  }

  public ngOnInit(): void {

    if (this.config.value.spinner !== undefined) {
      this.spinner = this.config.value.spinner;
    }
    this.searchCtrl.valueChanges.pipe(debounceTime(300),
      distinctUntilChanged()).subscribe(val => {
      if (this.addSearch) {
        this.onSearch(val);
      }
    });
    this.initDataTable();
  }

  public initDataTable(): void {
    this.route = this.config.value.route;
    this.columns = this.config.value.columns;
    this.colSpans = this.config.value.colSpans;
    if (this.route) {
      const searchableCols = this.columns.filter((column: any) => (column.searchable === true));
      this.setFilterColumns(searchableCols);
      const index = this.config.value.defaultSortColumnIndex;
      if (index != null && index >= 0) {
        this.setDefaultSorting(this.columns[index].searchKey, this.columns[index].sortable);
      }
      this.loadDatatable();
    }
  }

  public setFilterColumns(columns: any[]): void {
    this.searchItems = columns;
    if (columns && columns.length) {
      this.searchBy = columns[0].searchKey;
      this.searchName = columns[0].name;
    }
  }

  async pagination(): Promise<any> {
    this.dataLoaded = false;


    if (this.config.value.spinner !== undefined) {
      this.spinner = this.config.value.spinner;
    } else {
      this.spinner = true;
    }
    if (this.page === 1) {
      this.rowsIndex = 0;
    } else {
      this.rowsIndex = (this.limit * (this.page - 1));
    }

    this.watchSuccess.next(true);

    if (this.expandAll) {
      this.expandAll = false;
      this.toggleExpandAll();
    }
    this.rows.next(this.config.value.data);
  }


  public onPage(event: any): void {
    this.selectedCount = 0;
    this.selectAll = false;
    this.page = event;
    this.onButtonClickPage.emit(event);
    this.pagination();
  }

  onPageChange(page: any): void {
    this.page = Number(page);
    this.configPaginate.currentPage = Number(page);
    this.onButtonClickPage.emit({page});
    this.routeUrl.params.subscribe(parameter => {
      this.datableRouter = parameter;
    });
  }

  public onSearch(data: any): void {
    this.onSearchKey.emit(data);
  }


  public clearSearch(): void {
    this.selectedCount = 0;
    this.selectAll = false;
    this.enableSearch = !this.enableSearch;
    this.searchValue = '';
    for (const item of this.searchItems) {
      delete this.params['filter[' + item.searchKey + ']'];
    }
    this.page = 1;
    this.pagination();
  }

  public loadDatatable(): void {
    this.sortCols = [];
    delete this.params['sort_by'];
    const index = this.config.value.defaultSortColumnIndex;
    if (index != null && index >= 0) {
      this.setDefaultSorting(this.columns[index].searchKey, this.columns[index].sortable);
    }
    this.clearSearch();
  }

  public setDefaultSorting(columnName: any, sortable: any): void {
    if (sortable && columnName) {
      if (!this.config.value.defaultSortOrderDesc) {
        this.sorting.ascending = true;
        this.params.sort_by = columnName;
        this.sortCols.push({'name': columnName, 'clicked': 1, 'direction': 1});
      } else {
        this.sorting.ascending = false;
        this.params.sort_by = '-' + columnName;
        this.sortCols.push({'name': columnName, 'clicked': 2, 'direction': -1});
      }
      this.sorting.column = columnName;
      this.sortByColumn = columnName;
    }
  }

  public sortClass(columnName: any): any {

    if (!this.config.value.multiSorting) {
      const asc = this.sorting.ascending;
      if (columnName === this.sorting.column) {
        return asc ? 'down' : 'up';
      } else {
        return;
      }
    } else {
      if (this.sortCols.length > 0) {
        const selectCol = this.filterArray(this.sortCols, 'name', columnName);
        if (selectCol) {
          return selectCol.direction === 1 ? 'down' : 'up';
        } else {
          return;
        }
      } else {
        return;
      }
    }
  }

  public changeSorting(columnName: any, sortable: any): void {
    if (sortable && columnName) {
      this.selectedCount = 0;
      this.selectAll = false;
      if (!this.config.value.multiSorting) {
        const sort = this.sorting;
        if (sort.column === columnName) {
          sort.ascending = !sort.ascending;
        } else {
          sort.column = columnName;
          sort.ascending = true;
        }
        if (sort.ascending) {
          columnName = columnName + ' ASC';
        }
        if (!sort.ascending) {
          columnName = columnName + ' DESC';
        }
        this.params.sort_by = columnName;
      } else {
        const tmp = this.sortByColumn.length ? this.sortByColumn.split(',') : [];
        const i = _.indexOf(tmp, columnName);
        if (i >= 0) {
          tmp.splice(i, 1);
        } else {
          tmp.push(columnName);
        }
        this.sortByColumn = tmp.join(',');
        const selectCol = _.find(this.sortCols, {name: columnName});
        if (selectCol) {
          this.click(selectCol);
          const index = _.findIndex(this.sortCols, {name: columnName});
          this.sortCols.splice(index, 1, selectCol);
        } else {
          this.sortCols.push({'name': columnName, 'clicked': 1, 'direction': 1});
        }

        const sortByarr = [];
        this.sortCols.forEach((obj: any) => {
          if (obj.direction !== 0) {
            sortByarr.push(obj.direction === 1 ? obj.name : '-' + obj.name);
          } else {
            if (this.sortCols.length !== 1) {
              const index = this.sortCols.indexOf(obj);
              this.sortCols.splice(index, 1);
            }
          }
        });
        if (sortByarr.length > 0) {
          this.params.sort_by = sortByarr.join();
        } else {
          delete this.params['sort_by'];
          if (this.sortCols[0].direction === 0) {
            this.sortCols[0].direction = 1;
            this.sortCols[0].clicked++;
          }
          const sortByName = this.sortCols[0].name;
          this.params.sort_by = this.sortCols[0].direction === 1 ? sortByName : 'ASC' + sortByName;
        }
      }
      this.params.limit = this.limit;
      this.onButtonClickSort.emit(this.params);
      // this.page = 1;
      this.pagination();
    }
  }

  public filterArray(arr: any[], key: string, value: any): any {
    if (arr && arr.length > 0) {
      const obj = arr.filter((trans) => (trans[key] === value));
      return obj[0];
    }
    return {};
  }

  public click(selectCol: any): void {
    selectCol.clicked++;
    this.chechDirection(selectCol);
  }

  public chechDirection(selectCol: any): void {
    const mod = selectCol.clicked % 3;
    switch (mod) {
      case 0:
        selectCol.direction = 0;
        break;
      case 1:
        selectCol.direction = 1;
        break;
      case 2:
        selectCol.direction = -1;
        break;
    }
  }

  public showPerPage(limit: any): void {
    this.searchValue = '';
    this.params.search = '';
    this.limit = limit.value;
    this.configPaginate.itemsPerPage = limit.value;
    const routeData = {
      page: this.configPaginate.currentPage,
      sort_by: 'created_at DESC',
      limit: this.limit
    };
    this.refreshBtn.emit(routeData);
  }

  public toggleExpandAll(): void {
    this.expandAll = !this.expandAll;
    const expand = this.expandAll;
    this.rows.value.forEach((obj: any) => {
      obj.expanded = expand;
    });
  }

  public onCheckboxChange(row: any): void {
    this.rows.value.forEach((obj: any) => {
      if (obj === row) {
        obj.selected = !obj.selected;
      }
    });
    this.selectedCount = this.getSelected().length;
  }

  public getSelected(): any {
    return this.rows.value.filter((x: any) => x.selected);
  }

  public onSelectAll(selectAll: any): void {
    this.selectAll = selectAll;
    this.rows.value.forEach((obj: any) => {
      obj.selected = selectAll;
    });
    this.selectedCount = this.getSelected().length;
  }

  public rereshTable(): void {
    const routeData = {
      page: this.datableRouter.page,
      sort_by: '',
      limit: this.limit
    };
    this.refreshBtn.emit(routeData);
  }

  exportAsXLSX(data): void {
  }


  listDataAction(option: string): void {
    if (option === 'excel') {
      // this.excelService.exportAsExcelFile(this.getSelected(), this.table.value.table);
    } else {
      this.remover.emit({data: this.getSelected(), type: option});
    }
  }

  rowClick(index): void {
    this.onRowClick.emit(index);
  }


}
