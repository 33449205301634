import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {User} from '../user/users.model';

@Injectable()
export class UsersService implements Resolve <any> {
  users: any[] = [];
  onUserChange: BehaviorSubject<any>;
  roles: any[] = [];

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient
  ) {
    this.onUserChange = new BehaviorSubject([]);
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getUsers(route.params)
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get users
   *
   * @returns {Promise<any>}
   */
  getUsers(route): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.api + environment.users + `?page=${route.page}&limit=${route.limit}&sort_by=${route.sort_by}`)
        .subscribe((response: any) => {
          for (let i = 0; i < response.length; i++) {
            response[i]['city'] = response[i].location.city;
            response[i]['street'] = response[i].location.street;
            response[i]['country'] = response[i].location.country;
            if (response[i].profile) {
              response[i]['firstName'] = response[i].profile.name;
              response[i]['name'] = response[i].profile.name;
              response[i]['surname'] = response[i].profile.surname;
              response[i]['phone'] = response[i].profile.phonePersonal;
              response[i]['username'] = response[i].profile.full_name;
              response[i]['amount'] = response[i].profile.contractAmount;
            }
          }
          this.users = response;
          this.onUserChange.next(response);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get roles
   *
   * @returns {Promise<any>}
   */
  getRoles(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.roles = [{id: 1, name: 'Admin'}, {id: 2, name: 'User'}];
      resolve(this.roles);
    });
  }


}
