import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import {DevseerDirectivesModule} from './directives/directives';
import {DevseerPipesModule} from './pipes/pipes.module';



@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        DevseerDirectivesModule,
        DevseerPipesModule
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        DevseerDirectivesModule,
        DevseerPipesModule
    ]
})
export class DevseerSharedModule
{
}
