export const locale = {
  lang: 'en',
  data: {
    users: {
      users: 'Students',
      username: 'Studentname',
      email: 'Email',
      name: 'Name',
      surname: 'Surname',
      phonePersonal: 'Phone Number',
      amount: 'Amount',
      action: 'Action',
      edit: 'Edit',
      delete: 'Delete',
      add: 'Add',
    },
    user: {
      add: 'Add',
      save: 'Save',
      new_user: 'New Student',
      account: 'Account',
      security: 'Security',
      plan_billing: 'Student Billing',
      team: 'Team',
      profile: 'Profile',
      profile_desc: 'Following information is publicly displayed!',
      username: 'Studentname',
      name: 'Name',
      surname: 'Surname',
      personal_info: 'Personal Information',
      personal_desc: 'Communication details in case we want to connect with you. These will be kept private.!',
      email: 'Email',
      location: 'Location',
      location_desc: 'Communication details in case we want to connect with you. These will be kept private.!',
      phonePersonal: 'Phone Number',
      street: 'Street',
      city: 'City',
      zip: 'Zip',
      country: 'Country',
      deactivation: 'Deactivation',
      deactivation_desc: 'Temporarily deactivate Account.',
      is_active_account: 'Active',
      change_pass: 'Change your password',
      pass_desc: 'You can only change your password twice within 24 hours!',
      password: 'Password',
      password_confirm: 'Confirm Password',
      change_role: 'Change roles',
      role_desc: 'Following information is publicly displayed, be careful!',
      role: 'Role',
      company: 'Company',
      contractAmount: 'Contract total amount',
      branch: 'Branches',
      generation: 'Registration Date',
      index: 'Index',
      father_name: 'Fathers Name',
      birthdate: 'Birth Date',
      uniemail: 'University Email',
      citizenship: 'Citizenship',
      idNumber: 'ID_Number',
      yearStudies: 'Year Of Studies',
      graduated: 'Graduated',
    },

    'ROLE': {
      'admin': 'Admin',
      'tenant': 'Admin Tenant',
      'device_user': 'Student'
    }
  }
};
