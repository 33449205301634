import {DevseerNavigation} from '../../@devseer/types';

export const navigation: DevseerNavigation[] = [
  {
    id: 'pages',
    title: 'Pages',
    translate: 'nav.pages',
    type: 'group',
    icon: 'pages',
    children: [
      {
        id: 'users',
        title: 'Users',
        translate: 'nav.user_management',
        type: 'item',
        icon: 'person',
        url: '/users/1/created_at DESC/25',
        roles: ['admin', 'super']
      }
    ]
  },
];
