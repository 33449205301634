import { NgModule } from '@angular/core';

import { DevseerWidgetComponent } from './widget.component';
import { DevseerWidgetToggleDirective } from './widget-toggle.directive';

@NgModule({
    declarations: [
        DevseerWidgetComponent,
        DevseerWidgetToggleDirective
    ],
    exports     : [
        DevseerWidgetComponent,
        DevseerWidgetToggleDirective
    ],
})
export class DevseerWidgetModule
{
}
