import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DcDatatableComponent} from './dc-datatable.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TranslateModule} from '@ngx-translate/core';
import {DevseerSharedModule} from '../../shared.module';
import {NgxPaginationModule} from 'ngx-pagination';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {_MatMenuDirectivesModule, MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {ActivatedRouteSnapshot, RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    TranslateModule,
    NgxPaginationModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    DevseerSharedModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    MatListModule,
    RouterModule
  ],
  declarations: [
    DcDatatableComponent
  ],
  exports: [
    DcDatatableComponent
  ],
  providers: []
})
export class DcDatatableModule {
}
