import {NgModule} from '@angular/core';

import {LayoutModule} from '../../layout/layout.module';
import {DevseerProgressBarModule} from '../../../@devseer/components';
import {UsersPageModule} from './users-management/users/users.module';

@NgModule({
  imports: [
    UsersPageModule,
    LayoutModule,
    DevseerProgressBarModule,
  ]
})
export class PagesModule {
}
