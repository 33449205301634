import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(private http: HttpClient) {
    }


    myProfile(): Observable<any> {
        const httpUrl = environment.api + environment.users + 'my_account';
        return this.http.get<any>(httpUrl);
    }

    putProfile(profile): Observable<any> {
        const httpUrl = environment.api + environment.users + `${profile.user_id}/` + environment.profiles + `${profile.id}/`;
        return this.http.put<any>(httpUrl, profile);
    }

    putSettings(settings): Observable<any> {
        const httpUrl = environment.api + environment.settings_update;
        return this.http.put<any>(httpUrl, settings);
    }

}
