export const environment = {
  production: false,
  hmr: false, // true if you want manual reload for dev purpose only.
  api: `https://obscure-coast-83976.herokuapp.com/`,
  // api: `http://localhost:3000/`,
  TIMESTAMP_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
  logListener: false,
  logLoader: false,
  debounceTime: 400,
  dateFormats: {
    fancyDate: 'EEE dd MMM yyyy',
    long: 'EEE dd MMM yyyy HH:mm',
    medium: 'y-MM-dd H:mm',
    date: 'y-MM-dd',
    time: 'HH:mm',
    monthYear: 'MMM y',
  },
  auth: {},
  authentication: 'auth/login/admin',
  student_transactions: '/users/create_student_transactions/',
  students: 'students/',
  categories: 'categories/',
  companies: 'companies/',
  orders: 'orders/',
  products: 'products/',
  prodtypes: 'prodtypes/',
  list_essential: 'products/list_essential',
  roles: 'roles/',
  navigation: 'navigations/',
  permissions: 'roles/permissions/',
  clone: 'screens/clone/',
  preupload: 'preupload/',
  medias: 'medias/',
  users: 'users/',
  clients: 'clients/',
  my_account: 'users/my_account',
  profiles: 'profiles/',
  settings_update: 'users/settings_update/',
  session_user: 'auth/refresh_token',
  session_check: 'session/check',
};
