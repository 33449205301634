export const locale = {
  lang: 'mk',
  data: {
    nav: {
      pages: 'Страници',
      dashboard: 'Контролна табла',
      students_management: 'Управување со студенти',
      user_management: 'Управување со корисници',
      companies_management: 'Менаџмент на компании',
      products_management: 'Управување со производи',
      categories_management: 'Управување на категории',
      orders: 'Нарачки'
    }
  },
};
