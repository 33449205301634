import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AdminGuardService {

  public _elementSubject: BehaviorSubject<any> = new BehaviorSubject(undefined);
  titleData$ = this._elementSubject.asObservable();
  setSubject(value) {
    if (value) {
      this._elementSubject.next(value);
    } else {
      this._elementSubject.next(null);
    }
  }
}
