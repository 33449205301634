import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import {DevseerNavigationService} from '../../../../../../@devseer/components/navigation/navigation.service';
import {DevseerPerfectScrollbarDirective} from '../../../../../../@devseer/directives/devseer-perfect-scrollbar/devseer-perfect-scrollbar.directive';
import {DevseerSidebarService} from '../../../../../../@devseer/components/sidebar/sidebar.service';
import {DevseerConfigService} from '../../../../../../@devseer/services/config.service';



@Component({
    selector     : 'navbar-vertical-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy
{
    devseerConfig: any;
    navigation: any;

    // Private
    private _devseerPerfectScrollbar: DevseerPerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DevseerConfigService} _devseerConfigService
     * @param {DevseerNavigationService} _devseerNavigationService
     * @param {DevseerSidebarService} _devseerSidebarService
     * @param {Router} _router
     */
    constructor(
        private _devseerConfigService: DevseerConfigService,
        private _devseerNavigationService: DevseerNavigationService,
        private _devseerSidebarService: DevseerSidebarService,
        private _router: Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(DevseerPerfectScrollbarDirective, {static: true})
    set directive(theDirective: DevseerPerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._devseerPerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._devseerNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._devseerPerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        this._devseerPerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                    });
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._devseerSidebarService.getSidebar('navbar') )
                    {
                        this._devseerSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Subscribe to the config changes
        this._devseerConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.devseerConfig = config;
            });

        // Get current navigation
        this._devseerNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._devseerNavigationService.getCurrentNavigation();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._devseerSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._devseerSidebarService.getSidebar('navbar').toggleFold();
    }
}
