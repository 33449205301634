import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import {DevseerConfirmDialogComponent} from './confirm-dialog.component';



@NgModule({
    declarations: [
        DevseerConfirmDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        DevseerConfirmDialogComponent
    ],
})
export class DevseerConfirmDialogModule
{
}
