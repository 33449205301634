import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(private http: HttpClient) {
    }

    authenticate(authparams): Observable<any> {
        const server = environment.api + environment.authentication;
        return this.http.post<any>(server, authparams);
    }


    refreshToken(token) {
        const user = JSON.parse(localStorage.getItem('profile'));
        const data = {session_token: localStorage.getItem('token'), id: user.id};
        const server = environment.api + environment.session_user;
        return this.http.post<any>(server, data);
    }


    sesssionCheck(): Observable<any> {
        const server = environment.api + environment.session_check;
        return this.http.get<any>(server);
    }

    getToken() {
        return localStorage.getItem('token');
    }

    logOutSession(): Observable<any> {
        const server = environment.api + 'auth/logout';
        return this.http.delete<any>(server);
    }

    // isLoggedIn() {
    //     return this.getToken() !== null;
    // }
    isLoggedIn(): Promise<boolean> {
        // Replace this with your actual authentication logic.
        // For example, you can check if a user is authenticated based on a token or session.
        const isAuthenticated = this.getToken() !== null
        return Promise.resolve(isAuthenticated);
      }
}
